@tailwind base;
@tailwind components;
@tailwind utilities;

@keyframes float {
  0%, 100% {
    transform: translateY(0) rotate(0deg);
  }
  50% {
    transform: translateY(-20px) rotate(10deg);
  }
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.animate-float {
  animation: float 20s infinite ease-in-out;
}

.animate-gradient {
  animation: gradient 8s infinite linear;
  background-size: 200% 200%;
}

.animate-bounce-slow {
  animation: bounce 3s infinite;
}